// When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
    var header = document.getElementById("myCategory");
    if (header) {
        var sticky = header.offsetTop;
    }
    console.log(header);
    if (window.pageYOffset > sticky) {
        header.classList.add("category-fixed");
    } else {
        header.classList.remove("category-fixed");
    }
}